:root {
    --text: #080807;
    --background: #FDFDFC;
    --primary: #FFC900;
    --secondary: #FF990D;
    --accent: #FFFB0D;
    --succes: #10f445;
    --error: #FF260D;
    --discard: #F56D41;
    --info: #5788E7;
    --grid-width: 35px;
    --grid-height: 25px;
}

@font-face {
    font-family: 'Hand Written';
    src: url('fonts/Gloria_Hallelujah/GloriaHallelujah-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'No Serif';
    src: url('fonts/Montserrat/Montserrat-VariableFont_wght.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'No Serif';
    src: url('fonts/Montserrat/Montserrat-Italic-VariableFont_wght.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

.App {
    font-family: 'No Serif', sans-serif;
    text-align: center;
}

h1, h2, h3 {
    text-align: center;
}

.home-page::before,
.game-page::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    height: 100vh;
    z-index: -1;
    background: radial-gradient(ellipse at top, var(--primary), transparent), radial-gradient(ellipse at bottom, var(--secondary), transparent);
    opacity: 0.2;
    filter: blur(0px);
}

.background-grid {
    background-image: linear-gradient(rgba(0, 0, 0, 0.05) 2px, transparent 1px), linear-gradient(90deg, rgba(0, 0, 0, 0.05) 2px, transparent 1px);
    background-size: var(--grid-width) var(--grid-height);
    overflow: hidden;
    position: relative;
    height: 100vh;
}

.home-page,
.result-page {
    height: 100vh;
}



.game-page {
    overflow: hidden;
}

.center-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.center-content-spread {
    width: 100vh;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.column-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}
.row-content {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
}
/* UI Elements */
h1 {
    font-size: 36px;
    font-weight: 900;
}

.option-menu {
    padding: 10px;
    border: 3px solid var(--text);
    background-color: var(--background);
    font-style: italic;
}

.ui-button-container {
    background-color: var(--primary);
    padding: 10px;
    border: 3px solid var(--text);
}

.button {
    display: inline-block;
    text-decoration: none;
    background-color: var(--background);
    color: var(--text);
    border: 2px solid var(--text);
    border-radius: 5px;
    transition: background-color 0.2s ease;
    margin: 5px;
    cursor: pointer;
}

    .button:hover {
        color: var(--background);
        background-color: var(--text);
        border-color: var(--background);
    }

.button-disabled {
    color: lightgray;
    background-color: gray;
    border-color: darkgray;
}
    .button-disabled:hover {
        color: transparent;
        background-color: gray;
        border-color: darkgray;
    }

.button-normal {
    padding: 10px 20px;
    font-weight: 700;
}
.button-small {
    padding: 5px 10px;
    font-style: italic;
    font-weight: 500;
}
.button-xsmall {
    font-size: 11px;
    padding: 2px 4px;
    font-weight: 300;
}

.button-delete {
    font-size: 8px;
    cursor : pointer;
}

.input-field {
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    margin-bottom: 10px;
}

.ui-card {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
}

.info-box {
    position: fixed;
    top: 10px;
    left: 10px;
    padding: 10px;
    color: var(--text);
    font-weight: bold;
}

    .info-box.success {
        background-color: var(--succes);
    }

    .info-box.error {
        background-color: var(--error);
    }

.error-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    margin-top: 10px;
}

    .error-list li {
        margin-bottom: 5px;
        color: #fff;
    }

.cartes-counter {
    position: fixed;
    bottom: 10px;
    left: 10px;
}
/* Game Elements */
.unselectable {
    overflow:hidden;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.ui-menu-container{
    position: absolute;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-around;
}
.ui-add-category-button {
    border-radius: 0px 0px 5px 5px;
}
.ui-add-card-button {
    font-size: smaller;
    height: 50%;
    padding: 5px;
    border-radius: 0px 0px 5px 5px;
}
.ui-tooltip-container {
    position: absolute;
    border-radius: 5px 0px 0px 5px;
    top: 10%;
    right: 0%;
    display: flex;
    flex-direction: column;
    font-size: x-small;
}
.ui-tooltip-arrow{
    position: absolute;
    transform: translate(-130%, 0%);
}
.ui-end-button {
    border-radius: 0px 0px 5px 5px;
    position: absolute;
    top: 0%;
    left: 0%;
}

.ui-end-game-button {
    border-radius: 5px 5px 0px 0px;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -100%);
}

.game-element-title {
    font-weight: 700;
    font-size: 22px;
    color: var(--text);
}
.game-txt {
    font-family: 'No Serif', sans-serif;
    font-size: 14px;
    color: var(--text);
    text-align: center;
}

.drop-zone-default {
    width: 100%;
    height: 100%;
    border: 2px dashed var(--primary);
    border-radius: 5px;
}

/* Card Elements */
.card-container {
    border: 2px solid var(--text);
    background-color: var(--background);
    border-radius: 5px;
}

.card {
    display: flex;
    align-items: start;
    justify-content: center;
    text-align: center;
    padding-top: 10%;
}

.card-txt {
    font-family: 'Hand Written', sans-serif;
    font-size: 14px;
    word-break: break-word;
    line-height: 80%;
}

.card-draggable {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 100%;
}

/* Category Elements */

.category-container {
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.25);
    border: 3px solid;
    background-color: var(--background);
    border-radius: 5px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.category-title {
    font-size: 16px;
    font-weight: 700;
    word-break: break-word;
}
.rename-icon-category {
    width: 18px;
    height: 18px;
}
.rename-icon-card {
    position: absolute;
    bottom: 3px;
    right: 3px;
    width: 10px;
    height: 10px;
}

.card-categorized-container {
    box-shadow: 0 2px 2px rgba(0, 0, 0, .5);
    border-bottom: rgba(50, 50, 50);
    width: 120px;
    height: 25px;
}
    .card-categorized-container :hover {
        transform: scale(1.1);
    }
.card-categorized-txt {
    font-size: 11px;
}
.card-categorized-counter {
    font-size: 9px;
}

/* Pile Elements */
.pile-container {
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.25);
    border: 3px solid var(--text);
    border-radius: 5px;
    background-color: var(--primary);
}
/* Discard Elements */
.discard-container {
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.25);
    border: 3px solid var(--text);
    border-radius: 5px;
    background-color: var(--discard);
}

.discard-drop-zone {
    border-radius: 5px;
    border: 1px solid var(--background);
    box-shadow: inset 0 0 5px 4px var(--background);
}
/* Discard Elements */
.info-container {
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.25);
    border: 3px solid var(--text);
    border-radius: 5px;
    background-color: var(--info);
}

.info-container-panel {
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    border: 3px solid var(--text);
    background-color: var(--background);
    padding: 5px 20px;
}

.panel-txt{
    font-weight : 600;
}

/* Result Page Elements */

.result-list {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 360px;
    overflow-x : hidden;
}

.category-node {
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.25);
    border: 2px solid var(--text);
    background-color: var(--primary);
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 10px;
}

.category-name {
    font-size: 22px;
    font-weight: 900;
    color: var(--text);
}

.jobs-list {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
}

.job-node {
    margin-bottom: 5px;
}

.job-text {
    font-size: 14px;
    margin-right: 5px;
    color: var(--text);
    width : 100%;
    cursor: pointer;
}

    .job-text:hover {
        font-weight: 700;
    }

.job-text-selected {
    font-weight: 900;
}

.delete-button {
    font-size: 12px;
    cursor: pointer;
    color: var(--text);
    font-weight: 200;
    font-style: italic;
}

    .delete-button:hover {
        font-weight: 400;
    }
